import React, { useEffect, useState } from "react";

// Boostrap Imports
import Container from 'react-bootstrap/Container';

import UpdateForm from "../forms/UpdateForm";

const UpdatePost = ({ match }) => {
    const requestedId = match.params.id;

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/postsDB")
            .then(res => res.json())
            .then(posts => setPosts(posts));
    }, [])

    const requestedPost = posts.filter(post => post._id === requestedId);
    
    console.log("requestedPost:");
    console.log(requestedPost);

    return (
        <div>
            <Container>
                {requestedPost.map(post => {
                    return <UpdateForm key={post._id} id={post._id} title={post.title} category={post.category} content={post.content} img={post.postImage}/>
                })}
            </Container>
        </div>

    )
}

export default UpdatePost;