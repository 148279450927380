import React, { useState } from "react";

// Bootstrap Imports
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function DeleteModal(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container className="delete-modal-container">
            <Button variant="outline-danger" className="modify-btn" onClick={handleShow}><i className="fas fa-trash-alt"></i></Button>

            <Modal show={show} onHide={handleClose}>
                <Form method="POST" action="api/deletePost">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Post</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this post?</Modal.Body>
                    <Modal.Footer>
                        <Form.Group>
                            <Form.Control type="text" name="postID" value={props.id} hidden/>
                        </Form.Group>
                        <Button variant="secondary" onClick={handleClose}>No, Cancel</Button>
                        <Button variant="danger" type="submit" onClick={handleClose}>Yes, Delete Post</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Container>

        // <Button variant="outline-danger" href={"delete/" + props.id} className="modify-btn"><i class="fas fa-trash-alt"></i></Button>
    )
}

export default DeleteModal;