import React from 'react';

// Boostrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Import Custom Components
import DropdownCustom from "../dropdowns/DropdownCustom";

function CreateForm() {

    return (
        <Container>
            <Form method="POST" action="api/createPost" encType="multipart/form-data">
                <Row>
                    <Col></Col>
                    <Col xl={6}>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter title" name="postTitle" />
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Label>Category</Form.Label>
                        <DropdownCustom query="categories" />
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col xl={10}>
                        <Form.Group>
                            <Form.Label>Enter Content</Form.Label>
                            <Form.Control as="textarea" rows={6} placeholder="What's on your mind?" name="postContent" />
                        </Form.Group>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col xl={5}>
                        <Form.Label>Select Image</Form.Label>
                        <Form.Group>
                            <input type="file" name="postImage" />
                        </Form.Group>
                    </Col>
                    <Col xl={5}>
                        <Form.Label>Sections</Form.Label>
                        <DropdownCustom query="sections" />
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col xl={1}>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Col>
                    <Col></Col>
                </Row>
            </Form>
        </Container>
    )
}

export default CreateForm;