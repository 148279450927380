import React from "react";

// Bootstrap Imports
import Dropdown from 'react-bootstrap/Dropdown';

function NavbarDropdownItemCustom(props) {
    return (
        <Dropdown.Item href={"/categories/" + props.category}>{props.category}</Dropdown.Item>
    )
}

export default NavbarDropdownItemCustom;