import React from "react";

// Custom Components
import CreateCategory from "../components/categories/CreateCategory"

export default function Category() {
    return (
        <div>
            <h1>Create a new Category</h1>
            <CreateCategory />
        </div>
    )
}