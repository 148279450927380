import React from "react";

// Bootstrap Imports
import Carousel from 'react-bootstrap/Carousel';

function CarouselItemCustom(props) {

    return (
        <Carousel.Item className={props.className}>
            <a href={"posts/" + props.id}>
                <div className="carousel-item-custom-overlay">
                    <img className="carousel-img" src={props.img} alt={props.img}/>
                </div>
                <Carousel.Caption>
                    <p className="carousel-caption-custom">{props.category}</p>
                    <h3>{props.title}</h3>
                    <p className="carousel-caption-custom">{props.date}</p>
                </Carousel.Caption>
            </a>
        </Carousel.Item>
    )
}

export default CarouselItemCustom;