import React from "react";

// Bootstrap Imports
import Dropdown from 'react-bootstrap/Dropdown';

function DropdownItemCustom(props) {
    return (
        <Dropdown.Item onClick={() => props.click(props.category)}>{props.category}</Dropdown.Item>
    )
}

export default DropdownItemCustom;