import React, { useEffect, useState} from "react";

// Boostrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

// Custom Components
import CategoryNavItem from "./CategoryNavItem";

function CategoryNavCustom() {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch("/api/categories")
            .then(res => res.json())
            .then(categories => setCategories(categories));
    }, [])

    // Grabbing the first x number categories in the categories array returned by the fetch request so that we can control how many genres are displayed in the feature area
    const featuredCategories = [];

    for (let i = 0; i < categories.length && i < 5; i++) {
        featuredCategories.push(categories[i]);
    }

    return (
        <div className="div-categories">
            <Container>
                <Row>
                    <Col>
                        <h2 id="categories-header">Categories</h2>
                        <Nav as="ul" className="flex-column">
                            {featuredCategories.map(category => {
                                return <CategoryNavItem key={category._id} category={category}/>
                            })}
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CategoryNavCustom;