import React from "react";

// Boostrap Imports
import Nav from 'react-bootstrap/Nav';

function CategoryNavItem(props) {
    return (
        <Nav.Item as="li" className="category-nav-item">
            <Nav.Link className="category-nav-link" href={"/categories/" + props.category.name}>{props.category.name}</Nav.Link>
        </Nav.Item>
    )
}

export default CategoryNavItem;