import React, { useEffect, useState } from "react";

// Bootstrap Imports
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

// Custom Imports
import DropdownItemCustom from "./DropdownItemCustom";
import Dropdown from "react-bootstrap/esm/Dropdown";

function DropdownCustom(props) {

    const [items, setItems] = useState([]);

    const queryParameter = props.query;

    useEffect(() => {
        fetch(`/api/${queryParameter}`)
            .then(res => res.json())
            .then(items => setItems(items));
    }, [queryParameter])

    // useEffect(() => {
    //     fetch("/api/categories")
    //         .then(res => res.json())
    //         .then(categories => setCategories(categories));
    // }, [])

    function checkObject(object) {
        return Object.keys(object).length === 0;
    }

    var doesItemExist = false
    if (checkObject(props) === false) {
        doesItemExist = true
    }

    var currentItem = doesItemExist ? props.category : "";

    const [item, setItem] = useState(currentItem);

    function getItem(itemSelected) {
        setItem(itemSelected);
    }

    const queryParamerterCapitalized = queryParameter.charAt(0).toUpperCase() + queryParameter.slice(1);

    return (
        <div>
            <InputGroup className="mb-3">
                <DropdownButton as={InputGroup.Prepend} variant="outline-success" title="Select">
                    {items.map(item => {
                        return <DropdownItemCustom key={item._id} category={item.name} click={getItem}/>
                    })}
                    <Dropdown.Divider />
                    <Dropdown.Item href={`/${queryParameter}/create`}>Create New {queryParamerterCapitalized}</Dropdown.Item>
                </DropdownButton>
                <FormControl aria-describedby="basic-addon1" value={item} name="postItems" readOnly/>
            </InputGroup>
        </div>
    )

}

export default DropdownCustom;