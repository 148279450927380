import React from "react";

// Bootstrap Imports
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function PostsListItem(props) {

    return (
        <tr className="posts-modal-table-row">
            <td>
                <Form.Check type="checkbox" onClick={() => { props.clicked(props.id)}} />
                {/* <Form.Check type="checkbox" onClick={() => { console.log("Clicked")}} /> */}
            </td>
            <td>{props.title}</td>
            {/* <td>{props.content.substring(0, 220) + "..."}</td> */}
            <td>{props.category}</td>
            <td><img src={props.img} className={"table-item-img"} alt={props.img} /></td>
            <td>{props.date}</td>
            {/* <td>
                <div className="text-nowrap d-flex justify-content-center">
                    <Button variant="outline-primary" href={"update/" + props.id} className="modify-btn"><i className="fas fa-edit"></i></Button>
                    <DeleteModal id={props.id} />
                </div>
            </td> */}
        </tr>
    )
}

export default PostsListItem;