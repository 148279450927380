import React, { useEffect, useState } from "react";

// Boostrap Imports
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

// Import Components
import TableItemCustom from "../table/TableItemCustom";
import PostsModal from "../modal/PostsModal";

export default function DashboardAccordion() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/postsDB")
            .then(res => res.json())
            .then(posts => setPosts(posts));
    }, []);

    // console.log("Initial Fetch...");
    // console.log(posts);

    var carouselPosts = [];
    var featuredPosts = [];
    var recentPosts = [];
    var aboutPosts = [];
    var allPosts = posts;

    // Add logic to filter through posts and push them to their respective sections
    carouselPosts = posts.filter(post => post.section === "Carousel");
    featuredPosts = posts.filter(post => post.section === "Featured");
    recentPosts = posts.filter(post => post.section === "Recent");
    aboutPosts = posts.filter(post => post.section === "About");

    const sections = [
        { name: "Carousel", sectionPosts: carouselPosts},
        { name: "Featured", sectionPosts: featuredPosts},
        { name: "Recent", sectionPosts: recentPosts},
        { name: "About", sectionPosts: aboutPosts},
        { name: "All Posts", sectionPosts: allPosts}
    ];

    return (
        <div className="accordion-div">
            {sections.map((section, i) => {
                return (
                <Accordion key={i}>
                    <Card className="accordion-card">
                        <Accordion.Toggle as={Card.Header} eventKey="0" className="accordion-card-header">{section.name}</Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="accordion-card-body">
                                <Row>
                                    <Col xl={6} className="accordion-card-col">
                                        {/* <a onClick={addPosts} className="accordion-link">Add</a> */}
                                        {/* <Button variant="outline-dark" onClick={addPosts} className="accordion-link">Add</Button> */}
                                        <PostsModal type="Add" posts={posts} section={section.name} />
                                    </Col>
                                    <Col xl={6} className="accordion-card-col">
                                        {/* <a onClick={removePosts} className="accordion-link">Remove</a> */}
                                        {/* <Button variant="outline-danger" onClick={addPosts} className="accordion-link">Remove</Button> */}
                                        <PostsModal type="Remove" posts={section.sectionPosts} section={""} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} className="dashboard-table-col">
                                        <Table striped bordered>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Title</th>
                                                    <th>Content</th>
                                                    <th>Category</th>
                                                    <th>Image</th>
                                                    <th>Date</th>
                                                    <th>Update</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {section.sectionPosts.map((post, i) => {
                                                    return <TableItemCustom key={i} index={i} id={post._id} title={post.title} content={post.content} category={post.category} img={post.postImage} date={post.date} />
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                )
            })}
        </div>
    )
}