import React, { useState } from 'react';
import {auth} from '../../../firebase.js';

// Boostrap Imports
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function Login() {

    const [credential, setCredentials] = useState({
        email: "",
        password: ""
    });

    function handleChange(event) {
        const { name, value } = event.target;

        setCredentials((prevValue) => {
            return {
                ...prevValue,
                [name]: value
            };
        });
    };

    function signIn() {
        // window.alert("Working");
      
        const promise = auth.signInWithEmailAndPassword(credential.email, credential.password);
        promise.catch(e => alert(e.message));
      
        auth.onAuthStateChanged(function(user) {
      
          if(user) {
            // Is signed in
            // Take user to a new page
            // window.location.href = "index.html";
            // window.alert("Signed In Successfully!");
          } else {
            // User is not signed in
            // window.alert("Access Denied");
          }
        });
    }
    
    return (
        <div>
            {/* <h1>Login Form Goes Here</h1> */}
            <Form className="login-form">
                <h2 className="login-form-text">Sign In</h2>
                <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" name="email" onChange={handleChange} placeholder="Enter email" value={credential.email} className="login-form-field" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" onChange={handleChange} placeholder="Password" value={credential.password} className="login-form-field" />
                </Form.Group>
                <Button variant="outline-light" onClick={signIn} className="login-form-button d-flex btn-lg">Sign In</Button>
            </Form>
        </div>
    )
}