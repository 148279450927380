import React from "react";

// Bootstrap Imports
import Carousel from 'react-bootstrap/Carousel';
import CarouselItemCustom from "../carousel/CarouselItemCustom";

function CarouselCustom(props) {

    return (
        <Carousel className="carousel-custom">
            {props.posts.map((post, i) => {
                return <CarouselItemCustom key={i} id={post._id} category={post.category} title={post.title} img={post.postImage} date={post.date}/>
            })}
        </Carousel>
    )
}

export default CarouselCustom;