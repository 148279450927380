import React from "react";

// Bootstrap Imports
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function MediumCardCustom(props) {
    return (
        <div>
            {/* Need to set a width of rem for the card class, example default is style={{ width: '18rem' }} */}
            <Card className="medium-card-custom" > 
                <Card.Img variant="top" src={props.image} className="medium-card-custom-image" />
                <Card.Body>
                    <Card.Text className="medium-card-custom-category">{props.category}</Card.Text>
                    <hr className="medium-card-custom-line"/>
                    <Card.Title className="medium-card-custom-title">{props.title}</Card.Title>
                    {/* <Button variant="alpha" className="medium-card-custom-button" href={"/posts/" + props.id}>Read More</Button> */}
                    <Button variant="custom-medium-outline-dark" href={"/posts/" + props.id}>Read More</Button>
                </Card.Body>
            </Card>
        </div>
    )
}

export default MediumCardCustom;