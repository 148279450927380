import React, { useEffect, useState } from "react";

// Boostrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Import Components
import MediumCardCustom from "../cards/MediumCardCustom";

const DisplayCategories = ({ match }) => {

    const requestedCategory = match.params.id;

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/postsDB")
            .then(res => res.json())
            .then(posts => setPosts(posts));
    }, [])

    var selectedPosts = [];
    var x;

    for (x = 0; x < posts.length; x++) {
        if (posts[x].category === requestedCategory) {
            console.log(posts[x]);
            selectedPosts.push(posts[x]);
        }
    }

    return (
        <div>
            <Container className="display-category-wall">
                <Row>
                    {selectedPosts.map(post => {
                        return (
                            <Col xl={4} className="display-category-col">
                                <MediumCardCustom key={post._id} id={post._id} category={post.category} title={post.title} image={post.postImage} />
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </div>

    )
}

export default DisplayCategories;