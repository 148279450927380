import React from 'react';

// Boostrap Imports
import Container from 'react-bootstrap/Container';

// Import Components
import Featured from "../components/featured/Featured";
import Subscribe from '../components/subscribe/Subscribe';
import CategoryNavCustom from "../components/categories/CategoryNavCustom";
import Recent from "../components/recent/Recent";

export default function Home() {

    return (
        <div>
            <div className="wall-div">
                <Container className="wall">
                    <Featured/>
                    <Subscribe />
                    <CategoryNavCustom />
                    <Recent/>
                </Container>
            </div>
        </div>
    )
}