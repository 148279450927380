import React, { useEffect, useState } from "react";

// Bootstrap Imports
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

// Import Components
import DashboardAccordion from "../components/dashboard/DashboardAccordian";
import TableItemCustom from "../components/table/TableItemCustom";

export default function Dashboard() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/postsDB")
            .then(res => res.json())
            .then(posts => setPosts(posts));
    }, [])

    // console.log(posts);

    return (
        <div>
            <Container>
                <h1 className="dashboard-h1">Dashboard</h1>
                <DashboardAccordion />
                {/* <Table striped bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Content</th>
                            <th>Category</th>
                            <th>Image</th>
                            <th>Date</th>
                            <th>Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {posts.map((post, i) => {
                            return <TableItemCustom key={i} index={i} id={post._id} title={post.title} content={post.content} category={post.category} img={post.postImage} date={post.date} />
                        })}
                    </tbody>
                </Table> */}
            </Container>
        </div>
    )
}