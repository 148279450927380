import React from "react";

// Bootstrap Imports
import Container from 'react-bootstrap/Container';

export default function Contact() {
    return (
        <Container>
            <div className="div-contact">
                <div>
                    <h1 className="contact-header">Contact Me!</h1>
                    <p className="contact-body">Have any feedback or want to collab? Contact me at <span id="contact-email">fromthegroundupwithtanya@gmail.com</span>!</p>
                </div>
            </div>
        </Container>
    )
}