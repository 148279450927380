import React from "react";

// Custom Components
import CreateSection from "../components/sections/CreateSection"

export default function Section() {
    return (
        <div>
            <h1>Create a new Category</h1>
            <CreateSection />
        </div>
    )
}