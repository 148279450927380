import React from "react";

// Bootstrap Imports
import Button from 'react-bootstrap/Button';

// Import Components
import DeleteModal from "../modal/DeleteModal";

function TableItemCustom(props) {

    return (
        <tr>
            <td>{props.index + 1}</td>
            <td>{props.title}</td>
            <td>{props.content.substring(0, 220) + "..."}</td>
            <td>{props.category}</td>
            <td><img src={props.img} className={"table-item-img"} alt={props.img} /></td>
            <td>{props.date}</td>
            <td>
                <div className="text-nowrap d-flex justify-content-center">
                    <Button variant="outline-primary" href={"update/" + props.id} className="modify-btn"><i className="fas fa-edit"></i></Button>
                    <DeleteModal id={props.id} />
                </div>
            </td>
        </tr>
    )
}

export default TableItemCustom;