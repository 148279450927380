import React from "react";

// Boostrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function CreateSection() {
    return (
        <Container>
            <Row>
                <Col></Col>
                <Col>
                <Form method="POST" action="/api/createSection">
                    <Form.Group>
                        <Form.Label>Create Section</Form.Label>
                        <Form.Control type="text" placeholder="Create Section" name="sectionName"/>
                        <Form.Text className="text-muted">A wonderful new section!</Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}

export default CreateSection;