import React, { useState } from 'react';
import {auth} from '../firebase.js';

// React Router Imports
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Posts from "../pages/Posts";
import Contact from "../pages/Contact";
import Create from "../pages/Create";
import Dashboard from "../pages/Dashboard";
import DisplayPost from "../components/posts/DisplayPost";
import DisplayCategories from '../components/categories/DisplayCategory';
import UpdatePost from "../components/posts/UpdatePost";
import Category from "../pages/Category";
import Admin from './admin/Admin';
import Section from '../pages/Section.js';

// Import Components
import Header from "../components/header/Header";

function App() {

  const [isLoggedIn, setLoggedIn] = useState(false);

  auth.onAuthStateChanged(function(user) {

    if (user) {
        setLoggedIn(true);
    } else {
    }

  });

  return (
    <Router>

      <Header />

        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/posts" exact component={Posts} />
          <Route path="/contact" component={Contact} />
          <Route path="/posts/:id" component={DisplayPost}/>
          <Route path="/categories/create" component={Category}/>
          <Route path="/categories/:id" component={DisplayCategories}/>
          <Route path="/sections/create" component={Section}/>
          <Route path="/update/:id" component={UpdatePost}/>
          <Route path="/admin" component={Admin}/>
          {isLoggedIn ? <Route path="/create" component={Create} /> : null }
          {isLoggedIn ? <Route path="/dashboard" component={Dashboard} /> : null }
        </Switch>

    </Router>
  );
}

export default App;