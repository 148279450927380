import React from "react";

// Bootstrap Imports
import Card from 'react-bootstrap/Card';

function NewCardCustom(props) {
    return (
        <div>
            <a href={"posts/" + props.id}>
                <Card className="card-custom text-white">
                    <div className="card-custom-overlay">
                        <Card.Img className="card-custom-img" src={props.img} alt={props.img} />
                    </div>
                    <Card.ImgOverlay className="card-custom-img-overlay d-flex flex-column justify-content-end">
                        <p className="card-custom-category">{props.category}</p>
                        <Card.Title className="card-custom-title">{props.title}</Card.Title>
                        <Card.Text className="card-custom-date">{props.date}</Card.Text>
                    </Card.ImgOverlay>
                </Card>
            </a>
        </div>
    )
}

export default NewCardCustom;