import React, { useEffect, useState } from "react";

// Bootstrap Imports
import DropdownButton from 'react-bootstrap/DropdownButton';

// Custom Imports
import NavbarDropdownItemCustom from "./NavbarDropdownItemCustom";

function NavbarDropdownCustom(props) {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch("/api/categories")
            .then(res => res.json())
            .then(categories => setCategories(categories));
    }, [])

    return (
        <div>
            <DropdownButton variant="nav-dropdown" title="Categories" className="navbar-categories-dropdown">
                {categories.map(category => {
                    return <NavbarDropdownItemCustom key={category._id} category={category.name}/>
                })}
            </DropdownButton>
        </div>
    )

}

export default NavbarDropdownCustom;