import React, { useEffect, useState } from "react";

// Bootstrap Imports
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Import Components
import CarouselCustom from "../carousel/CarouselCustom";
import NewCardCustom from "../cards/NewCardCustom";

function Featured() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/postsDB")
            .then(res => res.json())
            .then(posts => setPosts(posts));
    }, [])

    var carouselPosts = [];
    var featuredPosts = [];
    var recentPosts = [];

    // Add logic to filter through posts and push them to their respective sections
    carouselPosts = posts.filter(post => post.section === "Carousel");
    featuredPosts = posts.filter(post => post.section === "Featured");
    recentPosts = posts.filter(post => post.section === "Recent");

    // const carouselPosts = [];

    const featuredPostsControlled = [];

    for (let i = 0; i < featuredPosts.length && i < 4; i++) {
        featuredPostsControlled.push(featuredPosts[i]);
    }

    return (
        <div className="div-featured">
            <Row>
                <Col xl={6} className="carousel-custom-col">
                    <CarouselCustom posts={carouselPosts} />
                </Col>
                <Col xl={6} className="mini-posts">
                    <Row>
                        {featuredPostsControlled.map((post, i) => {
                            return (                        
                                <Col xl={6} key={i} className="card-custom-col">
                                    <NewCardCustom key={post._id} id={post._id} category={post.category} title={post.title} img={post.postImage} date={post.date} />
                                </Col>
                            )})
                        }
                    </Row>                      
                </Col>
            </Row>
        </div>
    )
}

export default Featured;