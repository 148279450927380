import React from "react";

// Import Components
import CreateForm from "../components/forms/CreateForm";

export default function Create() {
    return (
        <div>
            <h1>Create Page</h1>
            <CreateForm />
        </div>
    )
}