import React, { useEffect, useState } from "react";

// Bootstrap Imports
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// Import Components
import MediumCardCustom from "../components/cards/MediumCardCustom";

export default function About() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/postsDB")
            .then(res => res.json())
            .then(posts => setPosts(posts));
    }, []);

    var aboutPosts = [];
    
    aboutPosts = posts.filter(post => post.section === "About");

    return (
        <Container>
            <div className="div-about">
                <div>
                    {/* <img src="../images/Tanya-1.PNG" className="about-image" /> */}
                    {/* <Image src="../images/Tanya-2.PNG" className="about-image" rounded /> */}
                    <h1 className="about-header">About Me!</h1>
                    <p className="about-body">Hi, I am Tanya and I am a half full glass kind of girl! I always like to find the positive in any situation! I would like to share with you my tips and tricks to a day full of sunshine and smiles including how to get the best prices, getting the most for your money, shopping at Target (my happy place) and quick and easy cooking… basically how to live like a celebrity on a budget! Oh I can’t forget…I’ll also be sharing my happy ceramics finds and home decor! I hope you enjoy it!</p>
                </div>

                <h2 className="about-subheader">Get to know me better!</h2>
                <Row className="recent-posts-row">
                    {aboutPosts.map((post, i) => {
                        return (                        
                            <Col xl={4} key={i} className="recent-posts-col">
                                <MediumCardCustom key={post._id} id={post._id} category={"About Me"} title={post.title} image={post.postImage} />
                            </Col>
                        )})
                    }
                </Row>
            </div>
        </Container>
    )
}