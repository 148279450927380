import React, { useState } from "react";

// Bootstrap Imports
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

// Import Components
import PostsListItem from "../table/PostsListItem";

function PostsModal(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const type = props.type;
    var typeConditional = false;

    if (type === "Add") {
        typeConditional = true;
    }

    var posts = props.posts;

    const existingPosts = posts.filter(post => post.section === props.section);
    console.log("Existing Posts");
    console.log(existingPosts);

    var idsArray = [];

    // Go through the existing posts and save their ids to an array, then we'll iterate over that array and remove any of those ids from posts so they won't display
    for (var x = 0; x < existingPosts.length; x++) {
        idsArray.push(existingPosts[x]._id);

    }

    // Compares the two arrays and will remove any that have duplicate values, leaving us with only the posts that are not currently included
    posts = posts.filter(val => !existingPosts.includes(val));

    const [updatePosts, setUpdatePosts] = useState([]);

    function handleClick(id) {

        setUpdatePosts((prevIds) => {
            console.log(updatePosts);

            var exists = false;

            prevIds.indexOf(id) === -1 ? exists = true : prevIds.splice(updatePosts.indexOf(id), 1);

            if (exists === true) {
                exists = false;
                console.log("Should Be Adding");
                console.log(updatePosts);
                return [...prevIds, id];
            } else {
                console.log("Just the olds ones");
                console.log(updatePosts);
                return [...prevIds];
            }

        });

        // console.log(updatePosts);
    }

    return (
        <Container className="delete-modal-container">
            <Button variant={typeConditional ? "outline-success" : "outline-danger"} className="accordion-link" onClick={handleShow}>{type}</Button>

            <Modal show={show} onHide={handleClose} className="posts-modal">
                <Form method="POST" action="api/updateSection">
                    <Modal.Header closeButton>
                        <Modal.Title>{type} Posts</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Title</th>
                                {/* <th>Content</th> */}
                                <th>Category</th>
                                <th>Image</th>
                                <th>Date</th>
                                {/* <th>Update</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {posts.map((post, i) => {
                                return <PostsListItem key={i} index={i} id={post._id} title={post.title} content={post.content} category={post.category} img={post.postImage} date={post.date} clicked={handleClick} />
                            })}
                        </tbody>
                    </Table>

                    </Modal.Body>
                    <Modal.Footer>
                        <Form.Group>
                            <Form.Control type="text" name="selectedPosts" value={updatePosts} hidden/>
                            <Form.Control type="text" name="section" value={props.section} hidden/>
                        </Form.Group>

                        <Button variant="secondary" onClick={handleClose}>No, Cancel</Button>
                        <Button variant="danger" type="submit" onClick={handleClose}>Yes, {type} Posts</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Container>

    )
}

export default PostsModal;