import React, { useState } from 'react';

// Boostrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Import Custom Components
import DropdownCustom from "../dropdowns/DropdownCustom";

function UpdateForm(props) {

    const [form, setForm] = useState({
        postTitle: props.title,
        category: props.category,
        postContent: props.content,
        image: props.img,
        id: props.id
    });

    var oldImageName = form.image;

    for (var x = 0; x < 55; x++) {
        oldImageName = oldImageName.replace(oldImageName[x], '~');
    }

    var filteredImageName = oldImageName.split("").filter(function(c) {
        return c != "~";
    }).join("");

    console.log("Filtered: ");
    console.log(filteredImageName);

    console.log(props);

    function handleChange(event) {
        const { name, value } = event.target;

        setForm((prevValue) => {
            return {
                ...prevValue,
                [name]: value
            };
        });
    }

    const [doesFileExists, setDoesFileExists] = useState(false);

    function CheckFileField() {
        var file = document.getElementById("fileField")
        if (file.files.length === 0) {
            console.log("No Files Selected");
        } else {
            setDoesFileExists(true)
        }
    }

    return (
        <Container>
            <Form method="POST" action="api/updatePost" encType="multipart/form-data">
                <h3>Update Form</h3>
                <Row>
                    <Col></Col>
                    <Col xl={6}>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" name="postTitle" value={form.postTitle} onChange={handleChange}/>
                            <Form.Control type="text" name="postID" value={form.id} hidden/>
                            <Form.Control name="isFileSelected" value={doesFileExists} hidden/>
                            <Form.Control type="text" name="oldPostImage" value={filteredImageName} hidden/>
                        </Form.Group>
                    </Col>
                    <Col xl={4}>
                        <Form.Label>Category</Form.Label>
                        <DropdownCustom name="category" category={form.category} query="categories" />
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col xl={10}>
                        <Form.Group>
                            <Form.Label>Enter Content</Form.Label>
                            <Form.Control as="textarea" rows={6} placeholder="What's on your mind?" name="postContent" value={form.postContent} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col xl={6} className="d-flex justify-content-center update-form-img-col">
                        <img src={form.image} className="update-form-img" alt={form.image} />
                    </Col>
                    <Col xl={6} >
                        <Form.Label className="d-flex justify-content-center align-items-center update-form-upload-col">Select Image</Form.Label>
                        <Form.Group className="d-flex justify-content-center align-items-center update-form-upload-col">
                            <input type="file" name="postImage" id="fileField"></input>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col xl={1}>
                        <Button variant="primary" type="submit" onClick={CheckFileField}>Submit</Button>
                    </Col>
                    <Col></Col>
                </Row>
            </Form>
        </Container>
    )
}

export default UpdateForm;