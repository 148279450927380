import React from "react";

function Subscribe() {
    return (
        <div className="subscribe">
            <div id="sub-placeholder">
                <div className="sub-quote-div">
                    {/* <h1 id="sub-quote-start">It's not hard to be <span className="sub-quote-underline">successful</span>,</h1>
                    <h1 id="sub-quote-end">it's just super easy not to be!</h1>
                    <h6 id="sub-quote-author">- Someone Smart</h6> */}
                    {/* <h1 id="sub-quote-start">ALWAYS BRING YOUR OWN </h1>
                    <h1 id="sub-quote-end">SUNSHINE AND SMILE!</h1> */}
                    <h1 id="sub-quote-start">Always bring your own</h1>
                    <h1 id="sub-quote-end">sunshine and smile!</h1>
                    <h6 id="sub-quote-author">- <span id="sub-quote-brand">Tanya</span></h6>
                </div>
            </div>
        </div>
    )
}

export default Subscribe;