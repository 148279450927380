import React, { useEffect, useState } from "react";

// Bootstrap Imports
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Import Components
import MediumCardCustom from "../cards/MediumCardCustom";

function Recent() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/postsDB")
            .then(res => res.json())
            .then(posts => setPosts(posts));
    }, [])

    const recentPosts = [];

    for (let i = 0; i < posts.length && i < 3; i++) {
        recentPosts.push(posts[i]);
    }

    return (
        <div>

            <div className="recent-posts-header-div">
                <h3 id="recent-posts-header">Recent Posts</h3>
                <div className="recent-posts-line-div">
                    <hr className="recent-posts-line"/>
                </div>
            </div>
            <Row className="recent-posts-row">
                {recentPosts.map((post, i) => {
                    return (                        
                        <Col xl={4} key={i} className="recent-posts-col">
                            <MediumCardCustom key={post._id} id={post._id} category={post.category} title={post.title} image={post.postImage} />
                        </Col>
                    )})
                }
            </Row>
        </div>
    )
}

export default Recent;