import React from "react";

// Boostrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function CreateCategory() {
    return (
        <Container>
            <Row>
                <Col></Col>
                <Col>
                <Form method="POST" action="/api/createCategory">
                    <Form.Group>
                        <Form.Label>Create Category</Form.Label>
                        <Form.Control type="text" placeholder="Create Category" name="categoryName"/>
                        <Form.Text className="text-muted">A wonderful new category!</Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}

export default CreateCategory;