import React, { useEffect, useState } from "react";

// Boostrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PostCustom from "./PostCustom";

const DisplayPost = ({ match }) => {
    const requestedId = match.params.id;

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("/api/postsDB")
            .then(res => res.json())
            .then(posts => setPosts(posts));
    }, [])

    const requestedPost = posts.filter(post => post._id === requestedId);
    
    console.log("requestedPost:");
    console.log(requestedPost);

    return (
        <div>
            <Container className="post-custom-div">
                <Row>
                    <Col xl={12}>
                        <div className="back-option-div">
                            <a className="back-option" href="javascript:history.go(-1)"><i class="fas fa-arrow-left"> Back</i></a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {requestedPost.map(post => {
                            return <PostCustom key={post._id} id={post._id} title={post.title} content={post.content} category={post.category} img={post.postImage}/>
                        })}
                    </Col>
                </Row>
            </Container>
        </div>

    )
}

export default DisplayPost;