import React, { useState } from "react";
import {auth} from '../../firebase.js';

// Boostrap Imports
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

// Import Custom Components
import NavbarDropdownCustom from "../dropdowns/NavbarDropdownCustom";

function NavbarCustom() {

    const [isLoggedIn, setLoggedIn] = useState(false);

    auth.onAuthStateChanged(function(user) {
  
      if (user) {
          setLoggedIn(true);
      } else {
      }
  
    });

    return(
        <div>
            <Navbar collapseOnSelect expand="lg" className="navbar-custom">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/" className="nav-item-navbar">Home</Nav.Link>
                        <Nav.Link href="/about" className="nav-item-navbar">About</Nav.Link>
                        {/* <Nav.Link href="/categories" className="nav-item">Categories↓</Nav.Link> */}
                        <NavbarDropdownCustom />
                        <Nav.Link href="/contact" className="nav-item-navbar">Contact</Nav.Link>
                        {/* <Nav.Link href="/store" className="nav-item-navbar">Store</Nav.Link> */}
                    </Nav>
                    {isLoggedIn ?                     
                        <Nav className="ml-auto">
                            <Nav.Link href="/admin" className="nav-item-navbar">Admin(Testing)</Nav.Link>
                            <Nav.Link href="/create" className="nav-item-navbar">Create</Nav.Link>
                            <Nav.Link href="/dashboard" className="nav-item-navbar">Dashboard</Nav.Link>
                        </Nav>
                    :
                        null
                    }
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default NavbarCustom;