import React from "react";

// Import Components
import Brand from "../brand/Brand";
import NavbarCustom from "../navbars/NavbarCustom";

import SocialMediaNav from '../navbars/SocialMediaNav';
import Container from "react-bootstrap/esm/Container";

function Header() {
    return(
        <div>
            <Brand />
            <SocialMediaNav />
            <div className="nav-div">
                <Container className="nav-container">
                    <NavbarCustom />
                </Container>
            </div>
        </div>
    )
}

export default Header;