import React from "react";

function PostCustom(props) {
    return (
        <div className="postBody">
            <img src={props.img} alt={props.img} className="postImage"/>
            <h1 className="postTitle">{props.title}</h1>
            <hr className="post-breakline"/>
            <p className="postCategory">{props.category}</p>
            <pre className="postParagraph">{props.content}</pre>
        </div>    
    )
}

export default PostCustom;