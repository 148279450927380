import React from "react";

// Boostrap Imports
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

function SocialMediaNav() {
    return(
        <div className="social-media-nav-div">
            <Container>
                <Navbar className="social-media-nav">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            {/* <Nav.Link href="https://www.facebook.com/tanya.morris.7967" className="social-media-navbar-item" target="_blank"><i className="fab fa-facebook-f facebook-icon"></i></Nav.Link> */}
                            <Nav.Link href="https://www.instagram.com/jaxmodfarmhouse/" className="social-media-navbar-item" target="_blank"><i className="fab fa-instagram instagram-icon"></i></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </div>
    )
}

export default SocialMediaNav;