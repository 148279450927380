import React from "react";

function Brand() {
    return(
        <div className="brand-background">
            {/* <img src="./images/Brand-4.PNG" alt="brand" id="brand-image"/> */}
            <h1 className="brand-name">from the ground up with <span id="brand-name-tanya">Tanya</span></h1>
        </div>
    )
}

export default Brand;