import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCt0Fj0_Z3HVl7vmCG6A79xtSjheUHC9n0",
    authDomain: "from-the-ground-up-81045.firebaseapp.com",
    databaseURL: "https://from-the-ground-up-81045.firebaseio.com",
    projectId: "from-the-ground-up-81045",
    storageBucket: "from-the-ground-up-81045.appspot.com",
    messagingSenderId: "608232633366",
    appId: "1:608232633366:web:d0d9150207318ecb80f137",
    measurementId: "G-3MCFZWKLXZ"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth();