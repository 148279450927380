import React, { useState } from 'react';
import {auth} from '../../firebase.js';

// Boostrap Imports
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

// Import Components
import Login from './login/Login.js';

export default function Admin() {

    const [isLoggedIn, setLoggedIn] = useState(false);

    auth.onAuthStateChanged(function(user) {

        if (user) {
            setLoggedIn(true);
        } else {
        }

    });

    function signOut() {
        auth.signOut();
        window.location.href = "/admin";
    }
    
    return (
        <Container className="admin-wall">
            {isLoggedIn ? 
                <Row>
                    <Col xl={6}>
                        <h1>Welcome!!</h1>
                    </Col>
                    <Col xl={6}>
                        <Button type="button" onClick={signOut} variant="outline-primary">Sign Out</Button>
                    </Col>
                </Row> 
            : 
                <Row>
                    <Col xl={12}>
                        <Login />
                    </Col>
                </Row>
            }
        </Container>
    )
}